<template>
  <div>
    <page-header class="mb-4">
      {{ $t('reservationTableManage') }}
      <template #action>
        <div class="d-flex align-center">
          <v-spacer/>
          <v-btn
            :icon="!$vuetify.breakpoint.mdAndUp"
            class="success mr-2"
            @click="openAllTable"
          >
            <v-icon
              :center="!$vuetify.breakpoint.mdAndUp"
              :left="$vuetify.breakpoint.mdAndUp"
              color="white"
            >
              mdi-lock-open-variant-outline
            </v-icon>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              {{ $t('openAll') }}
            </template>
          </v-btn>
          <v-btn
            :icon="!$vuetify.breakpoint.mdAndUp"
            class="warning"
            @click="closeAllTable"
          >
            <v-icon
              :center="!$vuetify.breakpoint.mdAndUp"
              :left="$vuetify.breakpoint.mdAndUp"
              color="white"
            >
              mdi-lock-outline
            </v-icon>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              {{ $t('closeAll') }}
            </template>
          </v-btn>
        </div>
      </template>
    </page-header>
    <div
      class="mt-4"
      style="display: grid;grid-gap: 8px;grid-template-columns: repeat(auto-fill,minmax(150px,1fr));width: 100%"
    >
      <v-card
        v-for="(t) in tableList"
        :key="t.id"
        :color="t.ifReserve?'primary':'#eeeeee'"
        :dark="t.ifReserve"
        class="pa-4"
        elevation="0"
        @click="changeReserveStatus(t)"
      >
        <v-responsive
          :aspect-ratio="1"
          class="align-center"
          style="position: relative"
        >
          <div
            class="d-flex align-center flex-column"
            style="height: 100%"
          >
            <div class="text-h4 font-weight-medium">
              {{ t.name }}
            </div>
            <div class="mt-2 d-flex align-center font-weight-regular text-caption">
              <p>{{ $t('tableForPeople', [t.seatCount ? t.seatCount : 0]) }}</p>
            </div>
          </div>
        </v-responsive>
      </v-card>
    </div>
    <loading-dialog :just-wait="justWait"/>
  </div>
</template>

<script>
  import Table from '@/model/tableAndPerson/Table'
  import { addTableReserve, getReserveSettings, getReserveTable, removeTableReserve } from '@/common/Utlis/api'
  import LoadingDialog from '@/composable/LoadingDialog'
  import { showSuccessMessage } from '@/common/utils'
  import { today } from '@/common/Repository/DateRepository'
  import PageHeader from '@/composable/InK/typography/PageHeader'

  export default {
    name: 'UsefulReserveTable',
    components: { LoadingDialog, today, PageHeader },
    data: () => {
      return ({
        isLoading: null,
        sureToAdd: false,
        tableSeat: null,
        removeChair: null,
        isShowUsefulTable: false,
        justWait: false,
        tableList: [],
        needTable: null,
        showUsefulTable: null,
        reserveSetting: null,
        today,
        startTime: null,
        reservationDate: today,
        menu: false,
        openTime: null,
        datepicker: false,
        timeGap: null,
        adultCount: 1,
        childCount: 0,
      })
    },
    mounted () {
      const a = '2023-01-13 16:00:00:00'
      console.log(a.slice(0, -3))
      this.reloadData()
    },
    methods: {
      async openAllTable () {
        this.justWait = true
        const res = this.tableList.filter(it => !it.ifReserve)
        for (const item of res) {
          await addTableReserve(item.id)
        }
        this.justWait = false
        await this.reloadData()
        showSuccessMessage(this.$t('setTableSuccess'))
      },
      async closeAllTable () {
        this.justWait = true
        const res = this.tableList.filter(it => it.ifReserve)
        for (const item of res) {
          await removeTableReserve(item.id)
        }
        this.justWait = false
        await this.reloadData()
        showSuccessMessage(this.$t('clearTableSuccess'))
      },
      async changeReserveStatus (item) {
        if (!item.ifReserve) {
          this.justWait = true
          await addTableReserve(item.id)
          this.justWait = false
          await this.reloadData()
          showSuccessMessage(this.$t('setTableSuccess'))
        } else {
          this.justWait = true
          await removeTableReserve(item.id)
          this.justWait = false
          await this.reloadData()
          showSuccessMessage(this.$t('clearTableSuccess'))
        }
        await this.reloadData()
      },
      async reloadData () {
        const res = new Set((await getReserveTable()).map(it => parseInt(it.id)))
        this.tableList = (await Table.getList(true)).map(x => {
          x.ifReserve = res.has(x.id)
          return x
        })
        this.reserveSetting = (await getReserveSettings())
      },
    },
  }
</script>

<style scoped>

</style>
